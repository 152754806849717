import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import React from "react";
import { Link } from "react-router-dom";
import { Spinner } from "shared/components/controls";
import { formatBytes } from "shared/utils/format-utils";

const GET_INTERFACE_INFO_QUERY = gql`
    query getInterfaceInfo($device: String, $iface: String) {
        interface(device: $device, interface: $iface) {
            device
            name
            tags
            speed
            site
            port
            entityType
        }
    }
`;

export const InterfaceInfo = ({ iface, device }) => {
    const tagStyle = {
        border: "1px solid #D0D0D0",
        borderRadius: "4px",
        padding: "2px 3px",
        fontSize: "smaller",
    };

    // Fetch the interface info from the server
    const { loading, error, data } = useQuery(GET_INTERFACE_INFO_QUERY, {
        variables: { device, iface },
    });

    // Render the different load states
    if (loading) {
        return <Spinner style={{ padding: 100 }} />;
    } else if (error) {
        return <div>Unable to load interface details</div>;
    } else {
        const capacity = formatBytes(data?.interface?.speed * 1000000, 0, "", 1000, false); // multiply by 1m to get speed in bps rather than Mbps

        const tagDisplay = [];
        if (data?.interface.tags.length > 0 && data?.interface.tags[0] !== "") {
            const tags = data?.interface.tags[0].split("_");
            tags.forEach((tag, index) => {
                if (tag === "") {
                    return;
                } else {
                    tagDisplay.push(
                        <span style={tagStyle} key={`${index}`}>
                            <Link to={`/network/interfaces/list?filter=${tag}`}>{`${tag}`}</Link>
                        </span>
                    );
                }
                tagDisplay.push(<span key={`${index}-space`}>&nbsp;</span>);
            });
        }

        const site =
            data?.interface.site && data?.interface.entityType ? (
                <Link
                    to={`/${data?.interface.entityType}/view/${data?.interface.site}`}
                >{`${data?.interface.site}`}</Link>
            ) : (
                <div>Not site facing</div>
            );

        const port = data?.interface.port !== "" ? `${data?.interface.port}` : "-";

        return (
            <div>
                <dl className="row">
                    <dt className="col-sm-1">Name</dt>
                    <dd className="col-sm-11">{data?.interface.name}</dd>
                    <dt className="col-sm-1">Device</dt>
                    <dd className="col-sm-11">{data?.interface.device}</dd>
                    <dt className="col-sm-1">Port</dt>
                    <dd className="col-sm-11">{port}</dd>
                    <dt className="col-sm-1">Capacity</dt>
                    <dd className="col-sm-11">{capacity}</dd>
                    <dt className="col-sm-1">Site</dt>
                    <dd className="col-sm-11">{site}</dd>
                    <dt className="col-sm-1">Tags</dt>
                    <dd className="col-sm-11">{tagDisplay}</dd>
                </dl>
            </div>
        );
    }
};
